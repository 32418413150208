import React from 'react'
import { Col, Row } from 'react-bootstrap'
import InnerBanner from '../innerBanner'
import PageHeading from '../pageHeading'
import inside from '../../assets/img/inside.png'
import PrimaryBgHeading from '../primaryBgHeading'
import svg1 from '../../assets/img/svg/svg1.svg'
import svg2 from '../../assets/img/svg/svg2.svg'
import svg3 from '../../assets/img/svg/svg3.svg'
import svg4 from '../../assets/img/svg/svg4.svg'
import svg5 from '../../assets/img/svg/svg5.svg'
import svg6 from '../../assets/img/svg/svg6.svg'
import svg7 from '../../assets/img/svg/svg7.svg'
import svg8 from '../../assets/img/svg/svg8.svg'
import svg9 from '../../assets/img/svg/svg9.svg'
import IconBox from './IconBox'
import Bottom from '../home/Bottom'
import Loader from '../layout/loader'
import esgBanner from '../../assets/img/greenHydroImages/esgBanner.png'

const ESG = () => {
  return (
    <>
      <Loader />
      <InnerBanner title='ADVANCING RESPONSIBILITY' image={esgBanner} />
      <PageHeading
        heading='We are very conscious of carbon emissions, climate, and cultural values and continue to address and execute effective environmental, social, and governance (ESG) criteria using modern technology to mitigate and decelerate said environmental effects, while minimizing its carbon and environmental footprint. '
        align='text-start'
      />

      <div className='about-owner'>
        <div className='about-heading-wrap'>
          <div className='about-heading'>
            <div data-aos-duration='700' data-aos='fade-up'>
              <p className='text-center'>
                Expertise and Integrity - Our FOUNDERS & CEO
              </p>
            </div>
          </div>
        </div>

        <section className='brief-section esgBrief'>
          <Row className='g-0'>
            <Col lg='6'>
              <div
                data-aos-duration='700'
                data-aos='fade-right'
                className='h-100'
              >
                <div className='brief-content-wrap'>
                  <h4 className='brief-text'>
                    DELIVERING SUSTAINABLE SOLUTIONS
                  </h4>
                  <a href='/' className='text-btn'>
                    KNOW MORE{' '}
                    <svg
                      width='22'
                      height='12'
                      viewBox='0 0 22 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1 6H21M21 6L16.102 1M21 6L16.102 11'
                        stroke='#77ABD2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </Col>

            <Col lg='6'>
              <div data-aos-duration='700' data-aos='fade-left'>
                <div className='img-wrap'>
                  <img src={inside} alt='' className='brief-img' />
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <div className='mission-wrap'>
          <div className='mission-content'>
            <div data-aos-duration='700' data-aos='fade-up'>
              <p className='small-info-text'>
                Hydrogen196 is proud to adhere to the highest clean air
                standards, achieved through our sustainable practice of
                recycling hydrogen by injecting it back into the system. By
                replenishing resources and mitigating carbon dioxide emissions
                into the atmosphere, we prioritize environmental responsibility.
                Our commitment to reducing our carbon footprint ensures a
                cleaner and greener energy solution for a more sustainable
                future.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="playbook-wrap">
        <div className="about-content">
          <div data-aos-duration="700" data-aos="fade-up">
            <h4 className="about-title"> A Unique ESG Playbook </h4>
          </div>

          <div data-aos-duration="700" data-aos="fade-up">
            <p className="about-desc">
              {" "}
              Volutpat sed cras ornare arcu dui vivamus arcu felis. Quis enim
              lobortis scelerisque fermentum dui faucibus. Dictum at tempor
              commodo ullamcorper. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper. Sed cras ornare arcu dui vivamus arcu felis. Eu
              facilisis sed odio morbi. Laoreet non curabitur gravida arcu ac
              tortor dignissim convallis aenean.{" "}
            </p>
          </div>
        </div>

        <div className="d-flex flex-md-row flex-column align-items-lg-start align-items-center justify-content-lg-around justify-content-md-between svg-wrap">
          <div data-aos-duration="700" data-aos="fade-right">
            <div className="svg-link mb-4 mb-md-0">
              <svg
                width="263"
                height="93"
                viewBox="0 0 263 93"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M74.3996 34.2H32.8996C32.4996 34.2 32.0996 33.8 32.0996 33.4C32.0996 33 32.4996 32.6 32.8996 32.6H74.4996C74.8996 32.6 75.2996 33 75.2996 33.4C75.1996 33.8 74.8996 34.2 74.3996 34.2Z"
                  fill="#77ABD2"
                />
                <path
                  d="M25.9004 44.3C25.9004 43.9 26.3004 43.5 26.7004 43.5H74.5004C74.9004 43.5 75.3004 43.9 75.3004 44.3C75.3004 44.7 74.9004 45.1 74.5004 45.1H26.7004C26.2004 45.1 25.9004 44.8 25.9004 44.3Z"
                  fill="#77ABD2"
                />
                <path
                  d="M73.8 23.2H52.8C52.4 23.2 52 22.8 52 22.4C52 22 52.4 21.6 52.8 21.6H73.8C74.2 21.6 74.6 22 74.6 22.4C74.6 22.9 74.3 23.2 73.8 23.2Z"
                  fill="#77ABD2"
                />
                <path
                  d="M18.8 53.6V17.7C18.8 17.5 18.9 17.3 19 17.1L35.9 0.2C36 0.1 36.2 0 36.4 0H83.4C83.9 0 84.2 0.3 84.3 0.8V92.2C84.3 92.6 83.9 93 83.4 93H19.8C8.9 93 0 84.2 0 73.3C0 62.7 8.3 54 18.8 53.6ZM21.2 17.3H35.9V2.3L21.2 17.3ZM82.6 91.4V1.6H37.5V18C37.5 18.4 37.2 18.8 36.8 18.9H20.4V53.6C31.3 54 39.8 63.1 39.5 74C39.2 81.7 34.6 88.5 27.5 91.5L82.6 91.4ZM19.8 91.4C29.8 91.4 37.9 83.3 38 73.3C38 63.3 29.9 55.2 19.9 55.1C9.9 55 1.6 63.2 1.6 73.3C1.6 83.3 9.7 91.4 19.8 91.4Z"
                  fill="#77ABD2"
                />
                <path
                  d="M1.47734 73.582C1.47734 78.482 3.37734 83.082 6.87734 86.582C10.3773 90.082 14.9773 91.982 19.8773 91.982C24.7773 91.982 29.3773 90.082 32.8773 86.582C36.3773 83.082 38.2773 78.482 38.2773 73.582C38.2773 68.682 36.3773 64.082 32.8773 60.582C29.3773 57.082 24.7773 55.182 19.8773 55.182C14.9773 55.182 10.3773 57.082 6.87734 60.582C3.37734 64.082 1.47734 68.682 1.47734 73.582ZM0.477345 73.582C0.477345 62.882 9.17735 54.182 19.8773 54.182C30.5773 54.182 39.2773 62.882 39.2773 73.582C39.2773 84.282 30.5773 92.982 19.8773 92.982C9.17735 92.982 0.477345 84.282 0.477345 73.582Z"
                  fill="#77ABD2"
                />
                <path d="M19.7871 65.828V79.428" stroke="#77ABD2" />
                <path
                  d="M22.5869 78.428L19.7869 81.228L16.9869 78.428H22.5869Z"
                  fill="#77ABD2"
                />
                <path
                  d="M121.581 25.533L115.481 8.83301C114.881 7.23301 114.381 7.13311 113.481 6.93311V6.83301C113.981 6.83301 115.381 6.93311 116.181 6.93311C117.081 6.93311 118.081 6.93301 118.581 6.83301V6.93311C117.581 7.03311 117.281 7.33311 117.281 7.93311C117.281 8.23311 117.381 8.63306 117.581 9.13306L122.181 21.9331L125.781 11.6331C126.381 9.93306 126.681 8.83306 126.681 8.13306C126.681 7.43306 126.281 7.13311 125.381 6.93311V6.83301C125.881 6.83301 127.181 6.93311 128.081 6.93311C128.981 6.93311 129.181 6.93301 129.681 6.83301V6.93311C128.381 7.33311 127.881 8.13311 126.481 11.9331L121.681 25.533H121.581Z"
                  fill="#0A3E68"
                />
                <path
                  d="M132.982 25.033C132.482 25.033 132.082 25.033 131.082 25.033C130.082 25.033 129.782 25.033 129.182 25.033V24.9331C129.982 24.8331 130.282 24.6331 130.282 22.6331V15.1331C130.282 14.2331 130.282 14.033 129.482 13.833V13.7332C130.282 13.7332 131.182 13.5332 131.882 13.2332V22.7332C131.882 24.7332 132.182 24.833 132.982 25.033ZM131.282 11.1331C130.782 11.1331 130.482 10.733 130.482 10.333C130.482 9.83301 130.882 9.43311 131.282 9.43311C131.782 9.43311 132.182 9.83301 132.182 10.333C132.182 10.733 131.782 11.1331 131.282 11.1331Z"
                  fill="#0A3E68"
                />
                <path
                  d="M144.581 23.333C143.681 24.433 142.281 25.2332 140.481 25.2332C136.481 25.2332 134.581 22.2331 134.581 19.1331C134.581 15.7331 136.781 13.033 139.881 13.033C142.381 13.033 144.481 14.8331 144.481 17.4331H136.681C136.681 17.6331 136.681 17.9332 136.681 18.2332C136.681 22.2332 138.781 24.333 141.581 24.333C142.581 24.333 143.581 24.1331 144.581 23.1331V23.333ZM142.281 16.9331C142.181 14.6331 141.281 13.333 139.681 13.333C138.181 13.333 136.881 14.7331 136.581 16.9331H142.281Z"
                  fill="#0A3E68"
                />
                <path
                  d="M156.782 25.4331L153.982 17.333L150.482 25.4331H150.282L146.182 14.4331C145.782 13.4331 145.482 13.3332 144.882 13.2332V13.1331C145.182 13.1331 146.082 13.1331 146.982 13.1331C147.982 13.1331 148.782 13.1331 149.082 13.1331V13.2332C148.382 13.3332 147.982 13.4331 147.982 13.9331C147.982 14.1331 148.082 14.3331 148.182 14.6331L151.082 22.6331L153.782 16.4331L153.082 14.4331C152.782 13.5331 152.382 13.4332 151.482 13.2332V13.1331C152.482 13.2331 153.482 13.2332 154.382 13.2332C155.382 13.2332 155.882 13.2331 156.882 13.1331V13.2332C156.282 13.4332 155.782 13.633 155.082 15.033L157.482 22.6331L160.382 16.333C160.782 15.333 161.082 14.6332 161.082 14.2332C161.082 13.7332 160.682 13.433 159.782 13.333V13.2332C160.082 13.2332 160.682 13.2332 161.782 13.2332C162.582 13.2332 163.282 13.2332 163.582 13.2332V13.333C162.682 13.533 162.282 13.633 160.782 16.833L156.882 25.533H156.782V25.4331Z"
                  fill="#0A3E68"
                />
                <path
                  d="M170.481 19.1331C170.481 15.5331 173.081 13.033 176.481 13.033C179.981 13.033 182.481 15.6331 182.481 19.1331C182.481 22.4331 180.181 25.1331 176.581 25.1331C172.781 25.1331 170.481 22.8331 170.481 19.1331ZM180.381 18.9331C180.381 15.8331 179.481 13.333 176.281 13.333C172.981 13.333 172.481 16.733 172.481 19.333C172.481 22.133 173.181 24.7332 176.381 24.7332C179.781 24.8332 180.381 21.8331 180.381 18.9331Z"
                  fill="#0A3E68"
                />
                <path
                  d="M195.882 23.9331C195.482 24.5331 195.082 25.2332 194.082 25.2332C193.182 25.2332 192.682 24.5331 192.682 22.9331C191.882 24.3331 190.382 25.2332 188.682 25.2332C186.382 25.2332 184.882 23.9331 184.882 20.9331V15.033C184.882 14.133 184.682 13.8332 183.682 13.7332V13.6331C184.482 13.6331 185.782 13.4331 186.482 13.1331V20.9331C186.482 23.6331 187.282 24.7332 189.282 24.7332C190.782 24.7332 191.882 24.033 192.682 22.533V15.2332C192.682 14.3332 192.582 14.0331 191.582 13.9331V13.833C192.382 13.833 193.582 13.633 194.282 13.333V22.9331C194.282 23.7331 194.282 24.533 194.982 24.533C195.282 24.533 195.482 24.4331 195.882 24.1331V23.9331Z"
                  fill="#0A3E68"
                />
                <path
                  d="M200.781 25.033C200.281 25.033 199.881 25.033 198.881 25.033C197.881 25.033 197.581 25.033 196.981 25.033V24.9331C197.781 24.8331 198.081 24.6331 198.081 22.6331V15.033C198.081 14.133 198.081 13.9332 197.281 13.7332V13.6331C198.081 13.6331 198.981 13.4331 199.681 13.1331V14.2332C200.181 13.7332 201.081 13.1331 202.181 13.1331C203.481 13.1331 203.781 14.0332 203.781 15.2332H202.181C202.181 14.3332 201.881 13.9331 201.281 13.9331C200.881 13.9331 200.381 14.1331 199.681 14.9331V22.833C199.681 24.833 199.981 24.9331 200.781 25.1331V25.033Z"
                  fill="#0A3E68"
                />
                <path
                  d="M125.481 52.7332C125.481 54.8332 125.381 54.933 125.381 56.033C123.081 55.933 122.581 55.9331 120.281 55.9331C118.381 55.9331 115.781 55.9331 115.281 55.9331V55.833C116.481 55.633 116.681 55.233 116.681 53.533V40.1331C116.681 38.1331 116.281 38.033 115.181 37.833V37.7332C115.681 37.7332 116.581 37.833 118.381 37.833C122.181 37.833 124.181 37.8332 125.281 37.7332C125.281 37.9332 125.281 38.4332 125.281 38.7332C125.281 39.2332 125.281 40.2332 125.181 40.7332H124.981C124.981 38.5332 124.181 38.4331 120.581 38.4331C119.181 38.4331 118.881 38.5331 118.481 38.6331V46.033C123.081 46.033 123.281 46.0331 123.681 44.1331H123.781C123.781 44.9331 123.781 45.4331 123.781 46.4331C123.781 47.7331 123.781 48.1331 123.781 48.9331H123.681C123.281 46.8331 123.081 46.7332 118.481 46.7332V54.833C118.781 54.933 119.381 54.9331 120.581 54.9331C124.481 54.9331 124.981 54.9331 125.381 52.4331H125.481V52.7332Z"
                  fill="#0A3E68"
                />
                <path
                  d="M127.88 51.833C127.88 52.033 127.88 52.2331 127.88 52.4331C127.88 54.4331 129.78 55.533 131.78 55.533C133.48 55.533 135.18 54.4331 135.18 52.1331C135.18 47.1331 127.68 47.233 127.68 41.833C127.68 39.133 129.58 37.333 132.28 37.333C133.68 37.333 134.58 37.7332 135.58 37.7332C135.68 37.7332 135.88 37.7332 135.98 37.7332C135.98 38.2332 135.88 38.7331 135.88 39.4331C135.88 39.8331 135.88 40.433 135.88 40.833H135.68C135.28 38.933 133.98 37.9331 132.18 37.9331C130.58 37.9331 129.18 38.7332 129.18 40.7332C129.18 45.5332 136.58 45.3331 136.58 51.1331C136.58 54.2331 134.28 56.2332 131.38 56.2332C129.68 56.2332 128.98 55.7332 127.78 55.7332C127.68 55.7332 127.58 55.7332 127.38 55.7332C127.38 55.6332 127.38 55.3331 127.38 55.1331C127.38 54.5331 127.28 54.0331 127.28 53.4331C127.28 52.9331 127.38 52.333 127.58 51.833H127.88Z"
                  fill="#0A3E68"
                />
                <path
                  d="M152.581 49.7332C152.581 47.6332 152.081 47.6331 151.181 47.4331V47.333C151.681 47.333 152.581 47.333 153.481 47.333C154.281 47.333 154.981 47.333 155.481 47.333V47.4331C154.581 47.6331 154.381 47.7332 154.381 49.7332V54.6331C152.781 55.1331 151.381 56.2332 148.381 56.2332C141.981 56.2332 138.581 51.733 138.581 47.033C138.581 42.133 141.981 37.7332 148.081 37.7332C150.381 37.7332 152.181 38.2331 154.481 38.9331C154.481 39.5331 154.381 40.5332 154.381 41.2332C154.381 41.6332 154.381 42.0332 154.381 42.2332H154.281C153.981 39.4332 151.281 38.2332 148.181 38.2332C144.781 38.2332 140.781 40.5331 140.781 46.9331C140.781 52.1331 144.081 55.7332 148.381 55.7332C149.681 55.7332 151.481 55.333 152.481 54.333V49.7332H152.581Z"
                  fill="#0A3E68"
                />
                <path
                  d="M164.081 51.833C164.081 52.033 164.081 52.2331 164.081 52.4331C164.081 54.4331 165.981 55.533 167.981 55.533C169.681 55.533 171.381 54.4331 171.381 52.1331C171.381 47.1331 163.881 47.233 163.881 41.833C163.881 39.133 165.881 37.333 168.481 37.333C169.981 37.333 170.781 37.7332 171.781 37.7332C171.981 37.7332 172.081 37.7332 172.181 37.7332C172.181 38.2332 172.081 38.7331 172.081 39.4331C172.081 39.8331 172.081 40.433 172.081 40.833H171.981C171.581 38.933 170.281 37.9331 168.481 37.9331C166.881 37.9331 165.481 38.7332 165.481 40.7332C165.481 45.5332 172.881 45.3331 172.881 51.1331C172.881 54.2331 170.581 56.2332 167.681 56.2332C165.981 56.2332 165.281 55.7332 164.081 55.7332C163.881 55.7332 163.881 55.7332 163.681 55.7332C163.681 55.6332 163.681 55.3331 163.681 55.1331C163.681 54.5331 163.581 54.0331 163.581 53.4331C163.581 52.9331 163.681 52.333 163.881 51.833H164.081Z"
                  fill="#0A3E68"
                />
                <path
                  d="M180.881 54.2332C180.381 55.5332 179.281 56.2332 177.981 56.2332C176.681 56.2332 175.381 55.9332 175.381 53.2332V45.6331C175.381 44.6331 175.181 44.4332 174.181 44.2332V44.1331C175.781 44.1331 176.781 42.633 177.181 40.833H177.281C177.181 41.633 176.881 43.0331 176.881 44.1331H178.881C180.081 44.1331 180.481 44.133 180.881 43.533L180.981 43.7332C180.581 44.3332 180.281 44.733 179.981 45.533C179.981 45.433 180.081 45.3332 180.081 45.2332C180.081 44.8332 179.681 44.7332 178.881 44.7332H176.981V53.333C176.981 55.033 177.481 55.6331 178.781 55.6331C179.781 55.6331 180.281 55.333 180.781 54.333L180.881 54.2332Z"
                  fill="#0A3E68"
                />
                <path
                  d="M191.781 54.9331C191.381 55.5331 190.881 56.2332 189.981 56.2332C189.081 56.2332 188.581 55.5331 188.581 54.6331C187.581 55.5331 186.281 56.2332 184.881 56.2332C182.781 56.2332 181.681 54.933 181.681 53.533C181.681 51.733 183.181 50.333 185.681 50.333C186.681 50.333 187.781 50.6331 188.581 51.1331V46.9331C188.581 45.3331 187.981 44.4331 186.281 44.4331C184.981 44.4331 183.481 45.433 183.381 47.533H182.081C182.381 45.333 184.281 43.9331 186.481 43.9331C188.281 43.9331 190.181 44.5331 190.181 47.1331V53.7332C190.181 54.7332 190.381 55.1331 190.881 55.1331C191.081 55.1331 191.381 55.0332 191.681 54.7332L191.781 54.9331ZM188.581 51.6331C188.081 51.1331 187.081 50.833 186.181 50.833C184.381 50.833 183.481 52.133 183.481 53.333C183.481 54.433 184.281 55.533 185.781 55.533C186.881 55.533 187.881 55.0331 188.581 54.1331V51.6331Z"
                  fill="#0A3E68"
                />
                <path
                  d="M199.18 54.2332C198.68 55.5332 197.58 56.2332 196.28 56.2332C194.98 56.2332 193.68 55.9332 193.68 53.2332V45.6331C193.68 44.6331 193.48 44.4332 192.48 44.2332V44.1331C194.08 44.1331 195.08 42.633 195.48 40.833H195.68C195.48 41.633 195.28 43.0331 195.28 44.1331H197.28C198.48 44.1331 198.88 44.133 199.28 43.533L199.48 43.7332C199.08 44.3332 198.68 44.733 198.48 45.533H198.28C198.28 45.433 198.28 45.3332 198.28 45.2332C198.28 44.8332 197.98 44.7332 197.08 44.7332H195.28V53.333C195.28 55.033 195.78 55.6331 197.08 55.6331C198.08 55.6331 198.58 55.333 198.98 54.333L199.18 54.2332Z"
                  fill="#0A3E68"
                />
                <path
                  d="M210.081 54.333C209.181 55.433 207.781 56.2332 205.981 56.2332C201.981 56.2332 200.081 53.2331 200.081 50.1331C200.081 46.7331 202.281 44.033 205.381 44.033C207.881 44.033 209.981 45.8331 209.981 48.4331H202.181C202.181 48.6331 202.081 48.9332 202.081 49.2332C202.081 53.2332 204.181 55.333 206.981 55.333C207.981 55.333 208.981 55.1331 209.981 54.1331L210.081 54.333ZM207.881 47.9331C207.781 45.6331 206.881 44.333 205.281 44.333C203.781 44.333 202.481 45.7331 202.181 47.9331H207.881Z"
                  fill="#0A3E68"
                />
                <path
                  d="M230.181 56.033C229.681 56.033 229.181 56.033 228.281 56.033C227.381 56.033 226.981 56.033 226.381 56.033V55.9331C227.081 55.8331 227.481 55.6331 227.481 53.6331V48.533C227.481 45.133 225.981 44.4331 224.781 44.4331C223.981 44.4331 222.781 44.6332 221.181 45.7332C221.481 46.3332 221.681 47.2332 221.681 48.2332V53.533C221.681 55.533 221.981 55.633 222.781 55.833V55.9331C222.281 55.9331 221.881 55.9331 220.881 55.9331C219.881 55.9331 219.581 55.9331 218.981 55.9331V55.833C219.781 55.733 220.081 55.533 220.081 53.533V48.333C220.081 44.933 218.581 44.4331 217.381 44.4331C216.481 44.4331 215.381 44.7331 214.381 45.4331V53.6331C214.381 55.6331 214.681 55.7331 215.481 55.9331V56.033C214.981 56.033 214.581 56.033 213.581 56.033C212.581 56.033 212.281 56.033 211.681 56.033V55.9331C212.481 55.8331 212.781 55.6331 212.781 53.6331V46.033C212.781 45.133 212.781 44.9332 211.981 44.7332V44.6331C212.781 44.6331 213.681 44.4331 214.381 44.1331V45.1331C215.481 44.4331 216.781 44.1331 217.981 44.1331C219.281 44.1331 220.481 44.5331 221.081 45.6331C222.781 44.4331 224.281 44.1331 225.481 44.1331C227.581 44.1331 229.181 45.133 229.181 48.533V53.7332C229.181 55.7332 229.481 55.833 230.281 56.033H230.181Z"
                  fill="#0A3E68"
                />
                <path
                  d="M241.681 54.333C240.781 55.433 239.381 56.2332 237.581 56.2332C233.581 56.2332 231.681 53.2331 231.681 50.1331C231.681 46.7331 233.881 44.033 236.981 44.033C239.481 44.033 241.581 45.8331 241.581 48.4331H233.781C233.781 48.6331 233.681 48.9332 233.681 49.2332C233.681 53.2332 235.781 55.333 238.581 55.333C239.581 55.333 240.581 55.1331 241.581 54.1331L241.681 54.333ZM239.481 47.9331C239.381 45.6331 238.481 44.333 236.881 44.333C235.381 44.333 234.081 45.7331 233.781 47.9331H239.481Z"
                  fill="#0A3E68"
                />
                <path
                  d="M254.581 56.033C254.081 56.033 253.681 56.033 252.681 56.033C251.681 56.033 251.381 56.033 250.781 56.033V55.9331C251.581 55.8331 251.881 55.6331 251.881 53.6331V48.2332C251.881 45.1332 250.381 44.533 248.881 44.533C247.981 44.533 246.781 44.933 245.881 45.533V53.6331C245.881 55.6331 246.181 55.7331 246.981 55.9331V56.033C246.481 56.033 245.981 56.033 245.081 56.033C244.181 56.033 243.781 56.033 243.181 56.033V55.9331C243.981 55.8331 244.281 55.6331 244.281 53.6331V46.033C244.281 45.133 244.281 44.9332 243.481 44.7332V44.6331C244.281 44.6331 245.181 44.4331 245.881 44.1331V45.1331C246.981 44.4331 248.281 44.1331 249.481 44.1331C251.181 44.1331 253.481 44.6332 253.481 48.2332V53.833C253.481 55.833 253.781 55.9331 254.581 56.1331V56.033Z"
                  fill="#0A3E68"
                />
                <path
                  d="M262.381 54.2332C261.881 55.5332 260.781 56.2332 259.481 56.2332C258.181 56.2332 256.881 55.9332 256.881 53.2332V45.6331C256.881 44.6331 256.681 44.4332 255.681 44.2332V44.1331C257.281 44.1331 258.281 42.633 258.681 40.833H258.781C258.681 41.633 258.381 43.0331 258.381 44.1331H260.381C261.581 44.1331 261.981 44.133 262.381 43.533L262.481 43.7332C262.081 44.3332 261.781 44.733 261.481 45.533H261.281C261.281 45.433 261.381 45.3332 261.381 45.2332C261.381 44.8332 260.981 44.7332 260.181 44.7332H258.381V53.333C258.381 55.033 258.881 55.6331 260.181 55.6331C261.181 55.6331 261.681 55.333 262.181 54.333L262.381 54.2332Z"
                  fill="#0A3E68"
                />
                <path
                  d="M114.781 81.1331C114.781 77.5331 117.381 75.033 120.781 75.033C124.281 75.033 126.781 77.6331 126.781 81.1331C126.781 84.4331 124.481 87.1331 120.881 87.1331C117.081 87.1331 114.781 84.8331 114.781 81.1331ZM124.781 80.9331C124.781 77.8331 123.881 75.333 120.681 75.333C117.381 75.333 116.881 78.733 116.881 81.333C116.881 84.133 117.581 86.7332 120.781 86.7332C124.181 86.8332 124.781 83.8331 124.781 80.9331Z"
                  fill="#0A3E68"
                />
                <path
                  d="M131.982 87.033C131.382 87.033 130.982 87.033 130.082 87.033C129.182 87.033 128.782 87.033 128.182 87.033V86.9331C128.982 86.8331 129.282 86.6331 129.282 84.6331V75.6331H128.882V75.533L129.282 75.1331C129.282 72.2331 129.482 70.233 131.082 68.833C131.782 68.233 132.682 67.833 133.582 67.833C134.182 67.833 134.682 67.9332 134.982 68.2332L133.982 69.533C133.582 69.133 132.982 68.7332 132.382 68.7332C130.882 68.7332 130.882 70.6331 130.882 73.9331V75.1331H132.382C132.782 75.1331 132.882 75.133 132.882 75.033H132.982C132.982 75.433 132.982 75.933 132.982 76.533C132.982 75.833 132.882 75.6331 131.982 75.6331H130.982V84.6331C130.982 86.6331 131.282 86.7331 132.082 86.9331V87.033H131.982Z"
                  fill="#0A3E68"
                />
                <path
                  d="M145.282 87.033C144.782 87.033 143.982 87.033 143.082 87.033C142.182 87.033 141.482 87.033 140.882 87.033V86.9331C141.782 86.8331 142.082 86.6331 142.082 84.6331V71.2332C142.082 69.2332 141.682 69.1331 140.682 68.9331V68.833C141.182 68.833 141.982 68.9331 142.682 68.9331C144.082 68.9331 144.682 68.833 145.582 68.833C149.882 68.833 151.682 71.0332 151.682 73.7332C151.682 76.2332 149.882 78.7332 146.682 78.7332C145.982 78.7332 145.182 78.5331 144.682 78.1331L144.782 77.9331C145.282 78.1331 145.782 78.2332 146.282 78.2332C148.582 78.2332 149.582 76.4331 149.582 74.1331C149.582 71.2331 148.082 69.333 145.582 69.333C145.082 69.333 144.382 69.433 143.782 69.533V84.533C143.782 86.533 144.082 86.633 144.982 86.833V87.033H145.282Z"
                  fill="#0A3E68"
                />
                <path
                  d="M164.781 85.9331C164.381 86.5331 163.981 87.2332 162.981 87.2332C161.981 87.2332 161.581 86.5331 161.581 84.9331C160.681 86.3331 159.281 87.2332 157.581 87.2332C155.281 87.2332 153.781 85.9331 153.781 82.9331V77.033C153.781 76.133 153.581 75.8332 152.581 75.7332V75.6331C153.381 75.6331 154.681 75.4331 155.381 75.1331V82.9331C155.381 85.6331 156.281 86.7332 158.181 86.7332C159.681 86.7332 160.781 86.033 161.581 84.533V77.2332C161.581 76.3332 161.481 76.0331 160.481 75.9331V75.833C161.281 75.833 162.481 75.633 163.181 75.333V84.9331C163.181 85.7331 163.181 86.533 163.781 86.533C164.081 86.533 164.281 86.4331 164.681 86.1331L164.781 85.9331Z"
                  fill="#0A3E68"
                />
                <path
                  d="M169.781 87.033C169.281 87.033 168.781 87.033 167.881 87.033C166.981 87.033 166.581 87.033 165.981 87.033V86.9331C166.781 86.8331 167.081 86.6331 167.081 84.6331V77.033C167.081 76.133 167.081 75.9332 166.281 75.7332V75.6331C167.081 75.6331 167.981 75.4331 168.681 75.1331V76.2332C169.181 75.7332 170.081 75.1331 171.181 75.1331C172.481 75.1331 172.781 76.0332 172.781 77.2332H171.181C171.181 76.3332 170.781 75.9331 170.281 75.9331C169.881 75.9331 169.381 76.1331 168.681 76.9331V84.833C168.681 86.833 168.981 86.9331 169.781 87.1331V87.033Z"
                  fill="#0A3E68"
                />
                <path
                  d="M177.482 92.6331C176.982 92.6331 176.482 92.6331 175.582 92.6331C174.682 92.6331 174.282 92.6331 173.682 92.6331V92.533C174.482 92.333 174.782 92.2332 174.782 90.2332V77.033C174.782 76.133 174.682 75.8332 173.682 75.7332V75.6331C174.482 75.6331 175.682 75.4331 176.382 75.1331V76.333C177.882 75.333 179.082 75.1331 180.082 75.1331C183.582 75.1331 185.682 77.933 185.682 81.333C185.682 85.133 182.782 87.2332 179.682 87.2332C178.382 87.2332 177.282 86.8332 176.282 86.2332V90.333C176.282 92.333 176.582 92.4331 177.382 92.6331H177.482ZM176.382 85.533C176.982 86.133 178.082 86.6331 179.482 86.6331C181.182 86.6331 183.582 85.7332 183.582 81.2332C183.582 78.2332 181.882 75.6331 179.282 75.6331C178.482 75.6331 177.482 75.833 176.382 76.833V85.533Z"
                  fill="#0A3E68"
                />
                <path
                  d="M187.381 81.1331C187.381 77.5331 189.981 75.033 193.381 75.033C196.881 75.033 199.381 77.6331 199.381 81.1331C199.381 84.4331 197.081 87.1331 193.481 87.1331C189.681 87.1331 187.381 84.8331 187.381 81.1331ZM197.381 80.9331C197.381 77.8331 196.481 75.333 193.281 75.333C189.981 75.333 189.481 78.733 189.481 81.333C189.481 84.133 190.181 86.7332 193.381 86.7332C196.781 86.8332 197.381 83.8331 197.381 80.9331Z"
                  fill="#0A3E68"
                />
                <path
                  d="M207.881 77.833C207.681 76.533 206.781 75.333 205.081 75.333C203.881 75.333 202.881 76.133 202.881 77.533C202.881 80.433 208.381 79.933 208.381 84.033C208.381 86.233 206.281 87.2332 204.381 87.2332C203.681 87.2332 202.481 87.0331 201.281 86.4331C201.481 85.6331 201.381 84.4331 201.581 83.4331H201.681C201.681 83.6331 201.681 83.7331 201.681 83.9331C201.681 85.5331 202.381 86.833 204.381 86.833C205.681 86.833 206.681 86.0331 206.681 84.4331C206.681 81.2331 201.281 81.833 201.281 78.033C201.281 75.933 203.381 75.033 205.181 75.033C206.181 75.033 207.381 75.2331 207.981 75.4331C207.881 75.6331 207.881 75.9332 207.881 76.2332C207.881 76.7332 207.881 77.033 207.881 77.833Z"
                  fill="#0A3E68"
                />
                <path
                  d="M220.182 85.333C219.282 86.433 217.882 87.2332 216.082 87.2332C212.082 87.2332 210.182 84.2331 210.182 81.1331C210.182 77.7331 212.382 75.033 215.482 75.033C217.982 75.033 220.082 76.8331 220.082 79.4331H212.282C212.282 79.6331 212.282 79.9332 212.282 80.2332C212.282 84.2332 214.382 86.333 217.182 86.333C218.182 86.333 219.182 86.1331 220.182 85.1331V85.333ZM217.982 78.9331C217.882 76.6331 216.982 75.333 215.382 75.333C213.882 75.333 212.582 76.7331 212.282 78.9331H217.982Z"
                  fill="#0A3E68"
                />
              </svg>
            </div>
          </div>

          <div
            data-aos-duration="700"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <div className="svg-link">
              <svg
                width="213"
                height="93"
                viewBox="0 0 213 93"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M74.3996 34.2H32.8996C32.4996 34.2 32.0996 33.8 32.0996 33.4C32.0996 33 32.4996 32.6 32.8996 32.6H74.4996C74.8996 32.6 75.2996 33 75.2996 33.4C75.1996 33.8 74.8996 34.2 74.3996 34.2Z"
                  fill="#77ABD2"
                />
                <path
                  d="M25.9004 44.3C25.9004 43.9 26.3004 43.5 26.7004 43.5H74.5004C74.9004 43.5 75.3004 43.9 75.3004 44.3C75.3004 44.7 74.9004 45.1 74.5004 45.1H26.7004C26.2004 45.1 25.9004 44.8 25.9004 44.3Z"
                  fill="#77ABD2"
                />
                <path
                  d="M73.8 23.2H52.8C52.4 23.2 52 22.8 52 22.4C52 22 52.4 21.6 52.8 21.6H73.8C74.2 21.6 74.6 22 74.6 22.4C74.6 22.9 74.3 23.2 73.8 23.2Z"
                  fill="#77ABD2"
                />
                <path
                  d="M18.8 53.6V17.7C18.8 17.5 18.9 17.3 19 17.1L35.9 0.2C36 0.1 36.2 0 36.4 0H83.4C83.9 0 84.2 0.3 84.3 0.8V92.2C84.3 92.6 83.9 93 83.4 93H19.8C8.9 93 0 84.2 0 73.3C0 62.7 8.3 54 18.8 53.6ZM21.2 17.3H35.9V2.3L21.2 17.3ZM82.6 91.4V1.6H37.5V18C37.5 18.4 37.2 18.8 36.8 18.9H20.4V53.6C31.3 54 39.8 63.1 39.5 74C39.2 81.7 34.6 88.5 27.5 91.5L82.6 91.4ZM19.8 91.4C29.8 91.4 37.9 83.3 38 73.3C38 63.3 29.9 55.2 19.9 55.1C9.9 55 1.6 63.2 1.6 73.3C1.6 83.3 9.7 91.4 19.8 91.4Z"
                  fill="#77ABD2"
                />
                <path
                  d="M1.47734 73.582C1.47734 78.482 3.37734 83.082 6.87734 86.582C10.3773 90.082 14.9773 91.982 19.8773 91.982C24.7773 91.982 29.3773 90.082 32.8773 86.582C36.3773 83.082 38.2773 78.482 38.2773 73.582C38.2773 68.682 36.3773 64.082 32.8773 60.582C29.3773 57.082 24.7773 55.182 19.8773 55.182C14.9773 55.182 10.3773 57.082 6.87734 60.582C3.37734 64.082 1.47734 68.682 1.47734 73.582ZM0.477345 73.582C0.477345 62.882 9.17735 54.182 19.8773 54.182C30.5773 54.182 39.2773 62.882 39.2773 73.582C39.2773 84.282 30.5773 92.982 19.8773 92.982C9.17735 92.982 0.477345 84.282 0.477345 73.582Z"
                  fill="#77ABD2"
                />
                <path d="M19.7871 65.828V79.428" stroke="#77ABD2" />
                <path
                  d="M22.5869 78.428L19.7869 81.228L16.9869 78.428H22.5869Z"
                  fill="#77ABD2"
                />
                <path
                  d="M121.6 25.3001L115.5 8.6001C114.9 7.0001 114.4 6.9001 113.5 6.7001V6.6001C114 6.6001 115.4 6.7001 116.2 6.7001C117.1 6.7001 118.1 6.7001 118.6 6.6001V6.7001C117.6 6.8001 117.3 7.1001 117.3 7.7001C117.3 8.0001 117.4 8.4001 117.6 8.9001L122.2 21.7001L125.8 11.4001C126.4 9.7001 126.7 8.6001 126.7 7.9001C126.7 7.2001 126.3 6.9001 125.4 6.7001V6.6001C125.9 6.6001 127.2 6.7001 128.1 6.7001C129 6.7001 129.2 6.7001 129.7 6.6001V6.7001C128.4 7.1001 127.9 7.9001 126.5 11.7001L121.7 25.3001H121.6Z"
                  fill="#0A3E68"
                />
                <path
                  d="M132.9 24.8001C132.4 24.8001 132 24.8001 131 24.8001C130 24.8001 129.7 24.8001 129.1 24.8001V24.7001C129.9 24.6001 130.2 24.4001 130.2 22.4001V14.9001C130.2 14.0001 130.2 13.8001 129.4 13.6001V13.5001C130.2 13.5001 131.1 13.3001 131.8 13.0001V22.5001C131.8 24.5001 132.1 24.6001 132.9 24.8001ZM131.2 10.9001C130.7 10.9001 130.4 10.5001 130.4 10.1001C130.4 9.60007 130.8 9.20007 131.2 9.20007C131.7 9.20007 132.1 9.60007 132.1 10.1001C132.1 10.5001 131.7 10.9001 131.2 10.9001Z"
                  fill="#0A3E68"
                />
                <path
                  d="M144.5 23.1C143.6 24.2 142.2 25 140.4 25C136.4 25 134.5 22 134.5 18.9C134.5 15.5 136.7 12.8 139.8 12.8C142.3 12.8 144.4 14.6 144.4 17.2H136.6C136.6 17.4 136.5 17.7 136.5 18C136.5 22 138.6 24.1 141.4 24.1C142.4 24.1 143.4 23.9 144.4 22.9L144.5 23.1ZM142.3 16.7C142.2 14.4 141.3 13.1 139.7 13.1C138.2 13.1 136.9 14.5 136.6 16.7H142.3Z"
                  fill="#0A3E68"
                />
                <path
                  d="M156.7 25.2001L153.9 17.1001L150.4 25.2001H150.2L146.1 14.2001C145.7 13.2001 145.4 13.1001 144.7 13.0001V12.9001C145.1 12.9001 145.9 12.9001 146.8 12.9001C147.7 12.9001 148.6 12.9001 148.9 12.9001V13.0001C148.2 13.1001 147.8 13.2001 147.8 13.7001C147.8 13.9001 147.9 14.1001 148 14.4001L150.9 22.4001L153.6 16.2001L152.9 14.2001C152.6 13.3001 152.2 13.2001 151.3 13.0001V12.9001C152.3 13.0001 153.3 13.0001 154.2 13.0001C155.2 13.0001 155.7 13.0001 156.7 12.9001V13.0001C156.1 13.2001 155.6 13.4001 154.9 14.8001L157.3 22.4001L160.2 16.1001C160.6 15.1001 160.9 14.4001 160.9 14.0001C160.9 13.5001 160.5 13.2001 159.6 13.1001V13.0001C159.9 13.0001 160.5 13.0001 161.6 13.0001C162.4 13.0001 163.1 13.0001 163.4 13.0001C162.5 13.2001 162.1 13.3001 160.6 16.5001L156.7 25.2001Z"
                  fill="#0A3E68"
                />
                <path
                  d="M170.4 18.9C170.4 15.3 173 12.8 176.4 12.8C179.9 12.8 182.4 15.4 182.4 18.9C182.4 22.2 180.1 24.9 176.5 24.9C172.7 24.9 170.4 22.6 170.4 18.9ZM180.3 18.7C180.3 15.6 179.4 13.1 176.2 13.1C172.9 13.1 172.4 16.5 172.4 19.1C172.4 21.9 173.1 24.5 176.3 24.5C179.8 24.6 180.3 21.6 180.3 18.7Z"
                  fill="#0A3E68"
                />
                <path
                  d="M195.8 23.7001C195.4 24.3001 195 25.0001 194 25.0001C193.1 25.0001 192.6 24.3001 192.6 22.7001C191.8 24.1001 190.3 25.0001 188.6 25.0001C186.3 25.0001 184.8 23.7001 184.8 20.7001V14.8001C184.8 13.9001 184.6 13.6001 183.6 13.5001V13.4001C184.4 13.4001 185.7 13.2001 186.4 12.9001V20.7001C186.4 23.4001 187.2 24.5001 189.2 24.5001C190.7 24.5001 191.8 23.8001 192.6 22.3001V15.0001C192.6 14.1001 192.5 13.8001 191.5 13.7001V13.6001C192.3 13.6001 193.5 13.4001 194.2 13.1001V22.7001C194.2 23.5001 194.2 24.3001 194.9 24.3001C195.2 24.3001 195.4 24.2001 195.8 23.9001V23.7001Z"
                  fill="#0A3E68"
                />
                <path
                  d="M200.8 24.8001C200.3 24.8001 199.9 24.8001 198.9 24.8001C197.9 24.8001 197.6 24.8001 197 24.8001V24.7001C197.8 24.6001 198.1 24.4001 198.1 22.4001V14.8001C198.1 13.9001 198.1 13.7001 197.3 13.5001V13.4001C198.1 13.4001 199 13.2001 199.7 12.9001V14.0001C200.2 13.5001 201.1 12.9001 202.2 12.9001C203.5 12.9001 203.8 13.8001 203.8 15.0001H202.2C202.2 14.1001 201.9 13.7001 201.3 13.7001C200.9 13.7001 200.4 13.9001 199.7 14.7001V22.6001C199.7 24.6001 200 24.7001 200.8 24.9001V24.8001Z"
                  fill="#0A3E68"
                />
                <path
                  d="M126.6 52.3001C126.6 53.1001 126.1 54.1001 126.1 55.9001C123.5 55.8001 120.9 55.7001 118.2 55.7001C116.9 55.7001 114.6 55.7001 114.3 55.7001V55.6001C117.2 54.0001 123.9 49.2001 123.9 43.0001C123.9 39.3001 121.8 38.1001 119.7 38.1001C117.7 38.1001 115.7 39.4001 115.7 41.7001C115.7 42.5001 116.1 43.4001 116.8 43.9001C116.3 44.1001 116.1 44.2001 115.8 44.4001C115.2 43.7001 115 42.8001 115 41.9001C115 39.2001 117.2 37.1001 120.4 37.1001C123.2 37.1001 126 39.0001 126 43.0001C126 47.1001 121.4 51.0001 117.7 53.9001C120.1 53.9001 123.7 54.1001 124.4 54.1001C125.9 54.1001 126.1 53.7001 126.6 52.2001V52.3001Z"
                  fill="#0A3E68"
                />
                <path
                  d="M128.8 46.9001C128.8 42.2001 131 37.5001 135.4 37.5001C139.7 37.5001 142 42.2001 142 46.9001C142 53.5001 138.4 56.0001 135.4 56.0001C132.4 56.0001 128.8 53.5001 128.8 46.9001ZM139.6 46.6001C139.6 40.7001 138.2 38.0001 135.4 38.0001C132.6 38.0001 131.2 40.7001 131.2 46.6001C131.2 52.6001 132.6 55.4001 135.4 55.4001C138.2 55.4001 139.6 52.6001 139.6 46.6001Z"
                  fill="#0A3E68"
                />
                <path
                  d="M155.9 52.3001C155.9 53.1001 155.4 54.1001 155.4 55.9001C152.8 55.8001 150.2 55.7001 147.5 55.7001C146.2 55.7001 143.9 55.7001 143.6 55.7001V55.6001C146.5 54.0001 153.2 49.2001 153.2 43.0001C153.2 39.3001 151.1 38.1001 149 38.1001C147 38.1001 145 39.4001 145 41.7001C145 42.5001 145.4 43.4001 146.1 43.9001C145.6 44.1001 145.4 44.2001 145.1 44.4001C144.5 43.7001 144.3 42.8001 144.3 41.9001C144.3 39.2001 146.5 37.1001 149.7 37.1001C152.5 37.1001 155.3 39.0001 155.3 43.0001C155.3 47.1001 150.7 51.0001 147 53.9001C149.4 53.9001 153 54.1001 153.7 54.1001C155.2 54.1001 155.4 53.7001 155.9 52.2001V52.3001Z"
                  fill="#0A3E68"
                />
                <path
                  d="M162.9 55.8001C162.4 55.8001 161.6 55.8001 160.7 55.8001C159.8 55.8001 159.1 55.8001 158.5 55.8001V55.7001C159.4 55.6001 159.7 55.4001 159.7 53.4001V39.9001C159.2 40.4001 158.4 40.9001 157.6 41.4001L157.5 41.1001C159 40.1001 161 38.8001 161.4 37.2001H161.5V53.4001C161.5 55.4001 161.8 55.5001 162.7 55.7001V55.8001H162.9Z"
                  fill="#0A3E68"
                />
                <path
                  d="M182.1 52.5001C182.1 54.6001 181.9 54.7001 181.9 55.8001C179.6 55.7001 179.1 55.7001 176.8 55.7001C174.9 55.7001 172.3 55.7001 171.8 55.7001V55.6001C173 55.4001 173.2 55.0001 173.2 53.3001V40.0001C173.2 38.0001 172.8 37.9001 171.7 37.7001V37.6001C172.2 37.6001 173.1 37.7001 174.9 37.7001C178.7 37.7001 180.7 37.7001 181.8 37.6001C181.8 37.8001 181.8 38.3001 181.8 38.6001C181.8 39.1001 181.8 40.1001 181.7 40.6001H181.6C181.6 38.4001 180.8 38.3001 177.2 38.3001C175.8 38.3001 175.5 38.4001 175.1 38.5001V46.0001C179.7 46.0001 179.9 46.0001 180.3 44.1001H180.4C180.4 44.9001 180.4 45.4001 180.4 46.4001C180.4 47.7001 180.4 48.1001 180.4 48.9001H180.3C179.9 46.8001 179.7 46.7001 175.1 46.7001V54.8001C175.4 54.9001 176.1 54.9001 177.2 54.9001C181.1 54.9001 181.6 54.9001 182 52.4001H182.1V52.5001Z"
                  fill="#0A3E68"
                />
                <path
                  d="M184.5 51.6001C184.5 51.8001 184.5 52.0001 184.5 52.2001C184.5 54.2001 186.4 55.3001 188.4 55.3001C190.1 55.3001 191.8 54.2001 191.8 51.9001C191.8 46.9001 184.3 47.0001 184.3 41.6001C184.3 38.9001 186.3 37.1001 188.9 37.1001C190.4 37.1001 191.2 37.5001 192.2 37.5001C192.4 37.5001 192.5 37.5001 192.6 37.5001C192.6 38.0001 192.5 38.5001 192.5 39.2001C192.5 39.6001 192.5 40.2001 192.5 40.6001H192.4C192 38.7001 190.7 37.7001 188.9 37.7001C187.3 37.7001 185.9 38.5001 185.9 40.5001C185.9 45.3001 193.3 45.1001 193.3 50.9001C193.3 54.0001 191 56.0001 188.1 56.0001C186.4 56.0001 185.7 55.5001 184.5 55.5001C184.3 55.5001 184.3 55.5001 184.1 55.5001C184.1 55.4001 184.1 55.1001 184.1 54.9001C184.1 54.3001 184 53.8001 184 53.2001C184 52.7001 184.1 52.1001 184.3 51.6001H184.5Z"
                  fill="#0A3E68"
                />
                <path
                  d="M209.2 49.5001C209.2 47.4001 208.7 47.4001 207.8 47.2001V47.1001C208.3 47.1001 209.2 47.1001 210.1 47.1001C210.9 47.1001 211.6 47.1001 212.1 47.1001V47.2001C211.2 47.4001 211 47.5001 211 49.5001V54.4001C209.4 54.9001 208 56.0001 205 56.0001C198.6 56.0001 195.2 51.5001 195.2 46.8001C195.2 41.9001 198.6 37.5001 204.7 37.5001C207 37.5001 208.8 38.0001 211.1 38.7001C211.1 39.3001 211 40.3001 211 41.0001C211 41.4001 211 41.8001 211 42.0001C210.7 39.2001 208 38.0001 204.9 38.0001C201.5 38.0001 197.5 40.3001 197.5 46.7001C197.5 51.9001 200.8 55.5001 205.1 55.5001C206.4 55.5001 208.2 55.1001 209.2 54.1001V49.5001Z"
                  fill="#0A3E68"
                />
                <path
                  d="M127.8 86.8001C127.6 86.8001 127.5 86.8001 127.2 86.8001C124.6 86.7001 124.3 86.4001 121.4 80.9001C121.1 80.4001 120.5 79.1001 120 78.6001H118.4V84.4001C118.4 86.4001 118.7 86.5001 119.6 86.7001V86.8001C119.1 86.8001 118.3 86.8001 117.4 86.8001C116.5 86.8001 115.8 86.8001 115.2 86.8001V86.7001C116.1 86.6001 116.4 86.4001 116.4 84.4001V71.0001C116.4 69.3001 116.2 68.9001 115.2 68.7001V68.6001C116 68.7001 116.5 68.7001 117.6 68.7001C118.4 68.7001 119.6 68.6001 120.2 68.6001C124 68.6001 126.1 70.7001 126.1 73.6001C126.1 75.7001 124.7 77.7001 121.8 78.4001C122.6 79.0001 125.4 85.9001 127.7 86.7001V86.8001H127.8ZM119.5 78.1001C123.1 78.1001 124 76.3001 124 73.9001C124 71.3001 122.7 69.2001 120.1 69.2001C119.5 69.2001 119 69.2001 118.3 69.4001V78.1001H119.5Z"
                  fill="#0A3E68"
                />
                <path
                  d="M137.9 85.1C137 86.2 135.6 87 133.8 87C129.8 87 127.9 84 127.9 80.9C127.9 77.5 130.1 74.8 133.2 74.8C135.7 74.8 137.8 76.6 137.8 79.2H130C130 79.4 129.9 79.7 129.9 80C129.9 84 132 86.1 134.8 86.1C135.8 86.1 136.8 85.9 137.8 84.9L137.9 85.1ZM135.7 78.7C135.6 76.4 134.7 75.1 133.1 75.1C131.6 75.1 130.3 76.5 130 78.7H135.7Z"
                  fill="#0A3E68"
                />
                <path
                  d="M143 92.4C142.5 92.4 142.1 92.4 141.1 92.4C140.1 92.4 139.8 92.4 139.2 92.4V92.3C140 92.1 140.3 92 140.3 90V76.8C140.3 75.9 140.2 75.6 139.2 75.5V75.4C140 75.4 141.2 75.2 141.9 74.9V76C143.3 75 144.6 74.8 145.6 74.8C149.1 74.8 151.2 77.6 151.2 81C151.2 84.8 148.3 86.9 145.2 86.9C143.9 86.9 142.8 86.5 141.8 85.9V90C141.8 92 142.1 92.1 142.9 92.3V92.4H143ZM141.9 85.3C142.5 85.9 143.6 86.4 145 86.4C146.7 86.4 149.1 85.5 149.1 81C149.1 78 147.5 75.4 144.8 75.4C144 75.4 143 75.6 141.9 76.6V85.3Z"
                  fill="#0A3E68"
                />
                <path
                  d="M153 80.9C153 77.3 155.6 74.8 159 74.8C162.5 74.8 165 77.4 165 80.9C165 84.2 162.7 86.9 159.1 86.9C155.3 86.9 153 84.6 153 80.9ZM162.9 80.7C162.9 77.6 162 75.1 158.8 75.1C155.5 75.1 155 78.5 155 81.1C155 83.9 155.7 86.5 158.9 86.5C162.3 86.6 162.9 83.6 162.9 80.7Z"
                  fill="#0A3E68"
                />
                <path
                  d="M170.401 86.8001C169.901 86.8001 169.501 86.8001 168.501 86.8001C167.501 86.8001 167.201 86.8001 166.601 86.8001V86.7001C167.401 86.6001 167.701 86.4001 167.701 84.4001V76.8001C167.701 75.9001 167.701 75.7001 166.901 75.5001V75.4001C167.701 75.4001 168.601 75.2001 169.301 74.9001V76.0001C169.801 75.5001 170.701 74.9001 171.801 74.9001C173.101 74.9001 173.401 75.8001 173.401 77.0001H171.801C171.801 76.1001 171.401 75.7001 170.901 75.7001C170.501 75.7001 170.001 75.9001 169.301 76.7001V84.6001C169.301 86.6001 169.601 86.7001 170.401 86.9001V86.8001Z"
                  fill="#0A3E68"
                />
                <path
                  d="M180.801 85.0001C180.301 86.3001 179.201 87.0001 177.901 87.0001C176.601 87.0001 175.301 86.7001 175.301 84.0001V76.4001C175.301 75.4001 175.101 75.2001 174.101 75.0001V74.9001C175.701 74.9001 176.701 73.4001 177.101 71.6001H177.301C177.101 72.4001 176.901 73.8001 176.901 74.9001H178.901C180.101 74.9001 180.501 74.9001 180.901 74.3001L181.101 74.5001C180.701 75.1001 180.301 75.5001 180.101 76.3001H179.901C179.901 76.2001 179.901 76.1001 179.901 76.0001C179.901 75.6001 179.601 75.5001 178.701 75.5001H177.001V84.0001C177.001 85.7001 177.501 86.3001 178.801 86.3001C179.801 86.3001 180.301 86.0001 180.701 85.0001H180.801Z"
                  fill="#0A3E68"
                />
              </svg>
            </div>
          </div>
        </div>
      </div> */}

      <div className='page-heading-wrap pt-0'>
        <div data-aos-duration='700' data-aos='fade-up'>
          <h4 className='page-heading'>MATERIAL ESG FACTORS</h4>
        </div>
      </div>

      <div data-aos-duration='700' data-aos='fade-up'>
        <div className='mt-3 playbook-wrap'>
          <Row>
            <Col lg='3' className='d-none d-lg-block'></Col>
            <Col lg='9'>
              <p className='small-info-text'>
                Geothermal Core aims to create companies with a positive
                environmental and social impact. However, we are largely focused
                on performance in areas most material to each company. This
                concept of materiality allows us to engage meaningfully with our
                portfolio to directly link ESG with bottom-line investment
                returns.
              </p>
            </Col>
          </Row>
        </div>
      </div>

      <PrimaryBgHeading heading='Core AREAS OF ESG FOCUS' />

      <div className='focus-area'>
        <Row className='gx-4 gy-5 justify-content-center'>
          <Col xxl='2' lg='3' md='4' xs='6'>
            <div data-aos-duration='700' data-aos='fade-up'>
              <IconBox icon={svg1} name='Emissions / CO2 Avoidance' />
            </div>
          </Col>
          <Col xxl='2' lg='3' md='4' xs='6'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='200'
            >
              <IconBox icon={svg2} name='Safety' />
            </div>
          </Col>
          <Col xxl='2' lg='3' md='4' xs='6'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='300'
            >
              <IconBox icon={svg3} name='Power Sources' />
            </div>
          </Col>
          <Col xxl='2' lg='3' md='4' xs='6'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='400'
            >
              <IconBox icon={svg4} name='Recruitment' />
            </div>
          </Col>
          <Col xxl='2' lg='3' md='4' xs='6'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='500'
            >
              <IconBox icon={svg5} name='Efficiency / Utilization' />
            </div>
          </Col>
          <Col xxl='2' lg='3' md='4' xs='6'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='600'
            >
              <IconBox icon={svg6} name='Demographics and Engagement' />
            </div>
          </Col>
          <Col xxl='2' lg='3' md='4' xs='6'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='700'
            >
              <IconBox icon={svg7} name='Leak Detection' />
            </div>
          </Col>
          <Col xxl='2' lg='3' md='4' xs='6'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='800'
            >
              <IconBox icon={svg8} name='Governance' />
            </div>
          </Col>
          <Col xxl='2' lg='3' md='4' xs='6'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='900'
            >
              <IconBox icon={svg9} name='Cybersecurity' />
            </div>
          </Col>
        </Row>
      </div>

      <Bottom />
    </>
  )
}

export default ESG

const PrivacyPolict = () => {
  return (
    <>
      <div className=" terms">
        <h2 className="text-center">
          {" "}
          <u>PRIVACY POLICY </u>{" "}
        </h2>
        <h2 className="">Last revised on: January 31st, 2023 </h2>
        <p>
          This privacy policy (“Policy”) describes how Hydrogen196 Inc., a
          Delaware Corporation, together with its affiliates (the “Company”),
          collect, use, disclose, store, retain, process and share personal
          information of consumer users of this website, www.geothermalcore.com
          (the “Site”). This Policy also applies to any of our other websites
          that post this Policy. This Policy does not apply to websites that
          post different statements.{" "}
        </p>
        <br />
        <h3>
          <strong>WHAT WE COLLECT </strong>
        </h3>
        <p>We get information about you in a range of ways. </p>
        <p>
          <strong>Information You Give Us. </strong> In order to verify your
          identity and protect against fraud, among other reasons discussed
          below, we, or a third-party service hired by us, may collect your
          name, postal address, email address, phone number, username, password,
          demographic information (such as your gender and occupation) as well
          as other information you directly give us on our Site, including but
          not limited to, birth date, photograph, driver’s license, social
          security number, or other government issued ID. With regard to
          institutional clients, we or a third-party service hired by us, may
          collect the following information: entity name; state documents
          evidencing the entity’s existence (e.g. articles of incorporation,
          certificate of formation, etc.); Employer Identification Number
          (“EIN”); beneficial owners’ names, identification information, email
          addresses, phone numbers, and proofs of identity.{" "}
        </p>
        <p>
          <strong>Financial Data. </strong> In addition to identify
          verification, we or a third-party service hired by us, may collect
          various information regarding your finances through third parties in
          order to be able to perform the transactions you request on the
          platform and to ensure compliance with regulatory requirements (e.g.,
          anti-money laundering laws) and our own internal policies; in order
          for this to function, we must share certain of your Personal Data with
          these third parties. The information we receive from these third
          parties may include accounts you hold, balances, and transactions.{" "}
        </p>
        <p>
          <strong>Information We Get From Others. </strong> We may get
          information about you from other sources. We may add this to
          information we get from this Site.{" "}
        </p>
        <p>
          <strong>Marketing Data. </strong> We collect and process your
          preferences in receiving marketing from us, your email address,
          including hashed identifiers derived from email addresses for the
          purposes of cross-device tracking for targeted advertising, and where
          you may have seen Company advertisements. Information Automatically
          Collected. We automatically log information about you and your
          computer. For example, when visiting our Site, we log your computer
          operating system type, browser type, browser language, the website you
          visited before browsing to our Site, pages you viewed, how long you
          spent on a page, access times and information about your use of and
          actions on our Site.{" "}
        </p>
        <p>
          <strong>Cookies. </strong> We may log information using "cookies."
          Cookies are small data files stored on your hard drive by a website.
          We may use both session Cookies (which expire once you close your web
          browser) and persistent Cookies (which stay on your computer until you
          delete them) to provide you with a more personal and interactive
          experience on our Site. This type of information is collected to make
          the Site more useful to you and to tailor the experience with us to
          meet your special interests and needs. Please see our{" "}
        </p>
        <p>
          <strong>Cookie Policy for additional information. </strong> You can
          also adjust your browser settings to disable cookies, but it may
          affect your ability to use the Service and our website.{" "}
        </p>
        <p>
          <strong>Location Information. </strong> To provide the Services, we
          must collect information regarding your location. Your attempt to
          interfere with our collection of your location may cause you to lose
          access to the Services. Trade, Order, and Transaction Information.
          When you use our Services, we may collect and store information
          related to your orders, trades, transfers, balance information,
          deposits, withdrawals, payments, and other trading activity related to
          our Services. We may also collect information when you participate in
          any interactive features of the Services (such as API keys,
          cryptocurrency exchange digital asset allocations, configuration
          settings, and user referrals).{" "}
        </p>
        <br />
        <h3>
          <strong>USE OF PERSONAL INFORMATION </strong>{" "}
        </h3>
        <p>
          <strong>We may use your personal information as follows: </strong>{" "}
        </p>
        <li>
          {" "}
          We use your personal information to verify your identity, login
          credentials, and other information required to access the Services.{" "}
        </li>
        <li>
          {" "}
          We use your personal information to operate, maintain, and improve our
          sites, products, and services.{" "}
        </li>
        <li>
          {" "}
          We use your personal information to process and deliver contest
          entries and rewards.{" "}
        </li>
        <li>
          We use your personal information to respond to comments and questions
          and provide customer service.{" "}
        </li>
        <li>
          We use your personal information to send information including
          confirmations, invoices, technical notices, updates, security alerts,
          and support and administrative messages.{" "}
        </li>
        <li>
          We use your personal information to communicate about promotions,
          upcoming events, and other news about products and services offered by
          us and our selected partners.{" "}
        </li>
        <li>
          We use your personal information to link or combine user information
          with other personal information.{" "}
        </li>
        <li>
          We use your personal information to protect, investigate, and deter
          against fraudulent, unauthorized, or illegal activity.{" "}
        </li>
        <li>
          We use your information to fulfill all of our legal and regulatory
          requirements.{" "}
        </li>
        <li>
          We use your personal information to provide and deliver products and
          services customers request.{" "}
        </li>
        <li>
          We use your personal information to monitor and analyze trends, usage
          and activities in connection with the Website.{" "}
        </li>
        <li>
          We use your personal information to carry out any other purpose or
          reason for which the information was collected or given to the
          Company.{" "}
        </li>
        <br />
        <h3>
          <strong>SHARING OF PERSONAL INFORMATION </strong>{" "}
        </h3>
        <p>
          <strong>We may share personal information as follows: </strong>{" "}
        </p>
        <li>
          We sometimes share your information internally between employees and
          contractors of the Company, in particular in connection with
          activities undertaken jointly or in common with such group members
          and/or provide IT and system administration services and undertake
          management reporting.{" "}
        </li>
        <li>
          We do not sell, trade or otherwise transfer your Personal Data to
          third parties other than third parties who assist us in operating our
          Service, maintaining compliance with relevant laws, conducting our
          business or supporting our members, or providing you with applications
          or services integrated via our API.{" "}
        </li>
        <li>
          We may share personal information with your consent. For example, you
          may let us share personal information with others for their own
          marketing uses. Those uses will be subject to their privacy policies.{" "}
        </li>
        <li>
          We may share personal information when we do a business deal, or
          negotiate a business deal, involving the sale or transfer of all or a
          part of our business or assets. These deals can include any merger,
          financing, acquisition, or bankruptcy transaction or proceeding.{" "}
        </li>
        <li>
          We may share personal information for legal, protection, and safety
          purposes.{" "}
        </li>
        <li>We may share information to comply with laws. </li>
        <li>
          We may share information to respond to lawful requests and legal
          processes.{" "}
        </li>
        <li>
          We may share information to protect the rights and property of the
          Company, our agents, customers, and others. This includes enforcing
          our agreements, policies, and terms of use.{" "}
        </li>
        <li>
          We may share information in an emergency. This includes protecting the
          safety of our employees and agents, our customers, or any person.{" "}
        </li>
        <li>
          We may share information with those who need it to do work for us.{" "}
        </li>
        <p>
          We may also share aggregated and/or anonymized data with others for
          their own uses.{" "}
        </p>
        <p>
          <strong>Third parties. </strong> If you authorize applications or
          third-party integrations on or using our Service, these parties may
          receive detailed information about your account, your use of the
          Service, transaction history or even the ability to take actions on
          your behalf. Information collected by these applications or
          third-party integrations are subject to their terms and policies and
          are required by contract by Company to maintain the confidentiality
          and security of your Personal Data.{" "}
        </p>
        <br />
        <h3>
          <strong>
            ADVERTISING AND ANALYTICS SERVICES PROVIDED BY OTHERS{" "}
          </strong>{" "}
        </h3>
        <p>
          We may allow others to provide analytics services and serve
          advertisements on our behalf across the internet and in applications.
          These entities may use cookies, web beacons, device identifiers and
          other technologies to collect information about your use of the
          Services and other websites and applications, including your IP
          address, web browser, mobile network information, pages viewed, time
          spent on pages or in apps, links clicked and conversion information.
          This information may be used by Company and others to, among other
          things, analyze and track data, determine the popularity of certain
          content, deliver advertising and content targeted to your interests on
          our Services and other websites and better understand your online
          activity. For more information about interest-based ads, or to opt out
          of having your web browsing information used for behavioral
          advertising purposes, please visit www.aboutads.info/choices.{" "}
        </p>
        <br />
        <h3>
          <strong>TRANSFER OF DATA OUTSIDE OF US </strong>{" "}
        </h3>
        <p>
          Hydrogen196 Inc. is headquartered in the United States. Many of
          our affiliates and third-party service providers are based outside the
          US, so processing of your Personal Data may involve a transfer of your
          Personal Data outside the US and may be maintained or accessed in
          servers or files located in countries outside the US.{" "}
        </p>
        <p>
          By voluntarily providing your Personal Data on or via this website or
          app, you consent to its transfer, processing and storage in the United
          States or other countries outside the US, some which have not been
          deemed by the US to have “adequate” privacy safeguards.{" "}
        </p>
        <p>
          Whenever we transfer any Personal Data outside the US, we will put in
          place an adequate level of protection to ensure that any such
          transfers comply and are consistent with applicable US data protection
          laws.{" "}
        </p>
        <br />
        <h3>
          <strong>USER INFORMATION RETENTION </strong>{" "}
        </h3>
        <p>
          We retain the information described in this Privacy Policy for a
          reasonable amount of time as determined by us, even after you
          deactivate or cancel your account with the Company, to enforce the
          terms of our User Agreement and to comply with applicable laws and
          regulations.{" "}
        </p>
        <p>
          When it is no longer necessary to retain your information, we will
          securely delete it, subject to applicable law and regulations.{" "}
        </p>
        <p>
          In some circumstances, we may anonymize your information (so that it
          can no longer be associated with you) for research or statistical
          purposes in which case we may use this information indefinitely
          without further notice to you.{" "}
        </p>
        <p>
          If you request that we stop sending you marketing materials, we will
          continue to keep a record of your contact details and appropriate
          information to enable us to comply with your request not to be
          contacted by us.{" "}
        </p>
        <br />
        <h3>
          <strong>INFORMATION CHOICES AND CHANGES </strong>{" "}
        </h3>
        <p>
          Our marketing emails tell you how to “opt-out.” If you opt out, we may
          still send you non marketing emails. Non-marketing emails include
          emails about your accounts and our business dealings with you.{" "}
        </p>
        <p>
          You may send requests about personal information to our Contact
          Information below. You can request to change contact choices, opt-out
          of our sharing with others, and update your personal information.{" "}
        </p>
        <p>
          You can typically remove and reject cookies from our Site with your
          browser settings. Many browsers are set to accept cookies until you
          change your settings. If you remove or reject our cookies, it could
          affect how our Site works for you.{" "}
        </p>
        <br />
        <h3>
          <strong>INDIVIDUAL RIGHTS IN CERTAIN JURISDICTIONS </strong>{" "}
        </h3>
        <p>
          If you are located in certain jurisdictions, the applicable
          legislation allows you some or all of the following rights with
          respect to your Personal Data:{" "}
        </p>
        <p>
          <strong>To access the Personal Data we maintain about you. </strong>{" "}
          We will provide you free of charge with a copy of your Personal Data,
          but we may charge you a fee to cover our administrative costs if you
          request further copies of the same information.{" "}
        </p>
        <p>
          <strong>
            To be provided with information about how we process your Personal
            Data.{" "}
          </strong>{" "}
          This will include information on the categories of data, the sources
          from which it originated, the purpose and legal basis for the
          processing, the expected retention period, and the safeguards
          regarding data transfers to other jurisdictions, subject to the
          limitations set out in applicable laws and regulations.{" "}
        </p>
        <p>
          <strong>To correct your Personal Data. </strong> You have the right to
          ask us to rectify Personal Data you think is inaccurate or incomplete.
          In some cases, you will need to make certain of these changes yourself
          by using the tools we provide in the Data Sources.{" "}
        </p>
        <p>
          <strong>To have your Personal Data erased. </strong> You have the
          right to ask us to delete your Personal Data. In some cases, you will
          need to do the deletion yourself using the tools we provide in the
          Data Sources. We will decline your request for deletion if processing
          your Personal Data is necessary: (i) for the continued performance of
          a contract, (ii) to comply with our legal obligation; (iii) in pursuit
          of a legal action; (iv) to detect and monitor fraud; or (v) for the
          performance of a task in the public interest.{" "}
        </p>
        <p>
          <strong>To object to how we process your Personal Data. </strong>{" "}
          Where we process your Personal Data based on our legitimate interest
          (or that of a third party), you have the right to object to this
          processing on grounds relating to your particular situation if you
          feel it impacts on your fundamental rights and freedoms. We will
          decline your request where we have a compelling legitimate grounds for
          the processing which override your rights and freedoms, or where the
          processing is in connection with the establishment, exercise or
          defence of legal claims.{" "}
        </p>
        <p>
          <strong>To be informed about direct marketing. </strong> You have the
          right to request us to tell you how your Personal Data has been
          shared, if at all, with third parties for the third parties’ direct
          marketing purposes.{" "}
        </p>
        <p>
          <strong>
            To stop your Personal Data being used for direct marketing purposes.{" "}
          </strong>{" "}
          At your request, we will stop using your Personal Data for the purpose
          of direct marketing.{" "}
        </p>
        <p>
          If you want to stop us from contacting you in connection with
          marketing communications, please email us at the email address
          specified below.{" "}
        </p>
        <p>
          To restrict how we process your Personal Data. At your request, we
          will limit the processing of your Personal Data if:{" "}
        </p>
        <li>you dispute the accuracy of your Personal Data;</li>
        <li>
          your Personal Data was processed unlawfully and you request a
          limitation on processing, rather than the deletion of your Personal
          Data;
        </li>
        <li>
          we no longer need to process your Personal Data, but you require your
          Personal Data in connection with a legal claim, or
        </li>
        <li>
          you object to the processing pending verification as to whether an
          overriding legitimate ground for such processing exists.
        </li>
        <p>
          We may continue to store your Personal Data to the extent required to
          ensure that your request to limit the processing is respected in the
          future.{" "}
        </p>
        <p>
          The right to data portability. You have the right to receive your
          Personal Data in a structured, commonly used and machine-readable
          format, if:{" "}
        </p>
        <li>
          the processing of your Personal Data is based on your consent or
          required for the performance of a contract; or{" "}
        </li>
        <li>the processing is carried out by automated means. </li>
        <p>
          Please note information might already be available to you via the Data
          Sources.{" "}
        </p>
        <li>
          To withdraw any consent that you gave us to process your Personal
          Data. You have the right to withdraw any consent you may have
          previously given us at any time.{" "}
        </li>
        <li>
          To complain to a supervisory authority. If you are not satisfied with
          our response, you have the right to complain to or seek advice from a
          supervisory authority and/or bring a claim against us in any court of
          competent jurisdiction.{" "}
        </li>
        <p>
          To exercise the above rights, please contact us at the email address
          specified below. We will consider and process your request within a
          reasonable period of time. Please be aware that under certain
          circumstances, or in relation to certain types of data, including
          pseudonymous data, the applicable legislation may limit your exercise
          of these rights.{" "}
        </p>
        <p>
          <strong>CONTACT INFORMATION. </strong> We welcome your comments or
          questions about this privacy policy. You may contact us at
          jay@hydrogen196.com{" "}
        </p>
        <p>
          <strong>CHANGES TO THIS PRIVACY POLICY. </strong> We reserve the right
          to amend this Privacy Policy at any time. Amendments to this policy
          shall be posted to our website, along with a link to the last revised
          Privacy Policy. At any time, you may request a copy of this policy or
          any previously issued policies.{" "}
        </p>
      </div>
    </>
  );
};

export default PrivacyPolict;
